import React from "react"
import PropTypes from "prop-types"

import Header from "../Header"
import Footer from "../Footer"
import GoogleMaps from "../GoogleMaps"

import "../../styles/globals.scss"

const Layout = ({ children, hasSubNav, navId, loadMap, address, hasProgressBar }) => {
  return (
    <div className="layout-wrapper" id="layoutWrapper">
      <Header navId={navId} hasSubNav={hasSubNav} hasProgressBar={hasProgressBar} />
      <main style={{zIndex: 1000}} className={hasSubNav ? "has--SubNav" : "hasNo--SubNav"}>
        {children}
        {loadMap ? (
          <section className="bg-white">
            <GoogleMaps address={address} />
          </section>
        ) : (
          ""
        )}
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hasSubNav: PropTypes.bool,
  hasProgressBar: PropTypes.bool,
  navId: PropTypes.string,
  loadMap: PropTypes.bool,
  address: PropTypes.string,
}

Layout.defaultProps = {
  hasSubNav: false,
  hasProgressBar: false,
  navId: "",
  loadMap: true,
  address: "perth",
}

export default Layout

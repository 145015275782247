import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Navbar, Container, Nav } from "react-bootstrap"
import ProperLink from "./ProperLink"

import styles from "./styles.module.scss"

const SubHeader = ({ navLink, hasSubNav }) => {
  
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // find current scroll position
      const currentScrollPos = window.pageYOffset;

      // set state based on scroll location
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);

      // set state to new scroll position
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);

  }, [prevScrollPos, visible]);

  if (!hasSubNav) {
    return ""
  }

  return (
    <Navbar
      id={styles.subNavBar}
      expand="lg"
      variant="light"
      bg="light"
      fixed="top"
      className={`bg-white shadow ${visible ? 'd-none d-lg-flex' : 'd-none'} ${styles.headerNavSub}`}
    >
      <Container>
        <Nav>
          {navLink.subNavLinks.map((link, i) => {
            const { attrs = {}, external = false } = link;
            const linkHref = !!link.excludeMainLink ? link.to : `${navLink.to}${link.to}`;
            return (
              <ProperLink
                key={i}
                className={styles.subNavLink}
                activeClassName={styles.subNavActiveLink}
                to={linkHref}
                attrs={attrs}
                external={external}
              >
                {link.text}
              </ProperLink>
            )
          })}
        </Nav>
      </Container>
    </Navbar>
  )
}

SubHeader.propTypes = {
  hasSubNav: PropTypes.bool.isRequired,
  navLink: PropTypes.object
}

export default SubHeader
import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { MdEmail } from "react-icons/md"
import { FaPhoneAlt } from "react-icons/fa"
import { ImLocation } from "react-icons/im"

import PreviewCompatibleImage from "../PreviewCompatibleImage"

import "./styles.scss"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { sectionKey: { eq: "sections/footer" } }) {
        frontmatter {
          images {
            image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            imageAlt
          }
        }
      }
      site {
        siteMetadata {
          phoneNumberFormat
          siteEmail
          maps {
            perth {
              address
            }
            sydney {
              address
            }
          }
        }
      }
    }
  `)

  const { phoneNumberFormat, siteEmail, maps } = data.site.siteMetadata
  const { images } = data.markdownRemark.frontmatter

  return (
    <footer className="position-relative" style={{ zIndex: 1010 }}>
      <div style={{ backgroundColor: "#2C3846" }}>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-3 col-12 mb-5 mb-lg-0">
              <div className="d-flex flex-column text-white-5 align-items-center">
                <span className="font-size-lg-2 mb-5 font-bold text-white text-center">
                  MKI LEGAL IS A MEMBER OF THE FOLLOWING:
                </span>
                {images.map(({ image, imageAlt }, i) => {
                  return (
                    <div
                      className="mb-3 footer-images"
                      key={`footerImage-${i}`}
                    >
                      <PreviewCompatibleImage src={image} alt={imageAlt} />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="col-md-4 col-lg-3 col-12 mb-5 mb-md-0">
              <div className="d-flex flex-column text-white-50 pl-xl-3">
                <span className="font-size-lg-2 mb-3 font-bold text-white">
                  CONTACT US
                </span>
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0">
                    <FaPhoneAlt />
                  </div>

                  <span className="ml-3">{phoneNumberFormat}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0">
                    <MdEmail />
                  </div>

                  <span className="ml-3">{siteEmail}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0">
                    <ImLocation />
                  </div>
                  <span className="ml-3 w-100">{maps.perth.address}</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0">
                    <ImLocation />
                  </div>
                  <span className="ml-3 w-100">{maps.sydney.address}</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 col-12 mb-5 mb-md-0">
              <div className="d-flex flex-column text-white-50">
                <span className="font-size-lg-2 mb-3 font-bold text-white">
                  NAVIGATION
                </span>
                <Link
                  to="/lost-a-loved-one/letters-of-administration/"
                  className="mb-2 text-white-50 font-size-base"
                >
                  Letters of Administration
                </Link>
                <Link
                  to="/lost-a-loved-one/letters-of-administration-sydney-nsw/"
                  className="mb-2 text-white-50 font-size-base"
                >
                  Letters of Administration In Sydney
                </Link>
                <Link
                  to="/lost-a-loved-one/probate/"
                  className="mb-2 text-white-50 font-size-base"
                >
                  Probate
                </Link>
                <Link
                  to="/lost-a-loved-one/probate-in-sydney-nsw/"
                  className="mb-2 text-white-50 font-size-base"
                >
                  Probate In Sydney
                </Link>

                <Link
                  to="/make-a-will-online/"
                  className="mb-2 text-white-50 font-size-base"
                >
                  Wills
                </Link>
                <Link
                  to="/information/"
                  className="mb-2 text-white-50 font-size-base"
                >
                  Information
                </Link>
                <Link
                  to="/about-us/"
                  className="mb-2 text-white-50 font-size-base"
                >
                  About Us
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 col-12">
              <div className="d-flex flex-column text-white-50">
                <span className="font-size-lg-2 mb-3 font-bold text-white">
                  DISCLAIMER
                </span>
                <span className="d-block mb-4">
                  MKI Legal has its Liability Limited by a Scheme Approved Under
                  Professional Standards Legislation.
                </span>
                <span className="d-block mb-2">
                  * Based on prices as recommended by the WA legal costs
                  committee for probate and letters of administration work.
                </span>
                <br></br>
                <span className="d-block mb-2">
                  The media logos represent the media that featured MKI Legal.
                </span>
                <br></br>
                <Link
                  to="/nsw-information/"
                  className="mb-2 text-white-50 font-size-base"
                >
                  NSW Information
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#273340" }}>
        <div className="container">
          <div className="row py-4 text-white justify-content-center text-left text-md-center">
            <div className="col-12">
              <span className="d-block mb-4">
                MKI Legal has its Liability Limited by a Scheme Approved Under
                Professional Standards Legislation.
              </span>

              <div className="d-flex align-items-center justify-content-start justify-content-md-center">
                <Link
                  to="/terms-of-use"
                  className="font-size-base text-white mr-2"
                >
                  Terms of Service
                </Link>
                <span>|</span>
                <Link
                  to="/privacy-policy"
                  className="font-size-base text-white ml-2"
                >
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import { Link, graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap"
import { HiMenu } from "react-icons/hi"
import { FaPhoneAlt } from "react-icons/fa"

import ProperLink from "./ProperLink"
import SubHeader from "./SubHeader"
import ProgressBar from "./ProgressBar"

import styles from "./styles.module.scss"

const Header = ({ hasSubNav, navId, hasProgressBar }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phoneNumber
          phoneNumberFormat
        }
      }
    }
  `)

  const { phoneNumber, phoneNumberFormat } = data.site.siteMetadata

  const navLinks = [
    {
      to: "/lost-a-loved-one/",
      text: "Deceased Estates",
      navId: "lostALovedOne",
      subNav: true,
      subNavLinks: [
        {
          to: "",
          text: "Lost A Loved One?",
        },
        {
          to: "letters-of-administration/",
          text: "Letters of Administration",
        },
        {
          to: "probate/",
          text: "Probate",
        },
        {
          to: "/guides/deceased-estate",
          text: "Step-By-Step Guide",
          excludeMainLink: true,
        },
        {
          to: "download-app",
          text: "Free App [NEW]",
        },
      ],
    },
    {
      to: "/cremation/",
      text: "Cremation",
      navId: "cremation",
      subNav: true,
      subNavLinks: [
        {
          to: "",
          text: "Cremation",
        },
      ],
    },
    {
      to: "/make-a-will-online/",
      text: "Wills",
      navId: "willsOnline",
      subNav: false,
      subNavLinks: [
        {
          to: "",
          text: "Wills",
        },
      ],
    },
    {
      to: "/about-us",
      text: "About Us",
    },
    {
      to: "/information",
      text: "Information",
    },
  ]

  const subNavLink = navLinks.find(navLink => navLink.navId === navId)

  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      // find current scroll position
      const currentScrollPos = window.pageYOffset

      // set state based on scroll location
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10)

      // set state to new scroll position
      setPrevScrollPos(currentScrollPos)
    }
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [prevScrollPos, visible])

  return (
    <>
      <Navbar
        id={styles.navBarMain}
        expand="lg"
        variant="light"
        bg="light"
        fixed="top"
        className={`bg-white shadow align-items-center ${styles.headerNav}`}
      >
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <img
                src="/logo/logo-600x100.png"
                alt="EstatePlus Logo"
                className={styles.logo}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarNav"
            className={styles.menuToggle}
          >
            <HiMenu className={styles.menuIcon} />
          </Navbar.Toggle>
          <Navbar.Collapse
            id="navbarNav"
            className="justify-content-end py-4 py-lg-0"
          >
            <Nav className="flex-grow-1 justify-content-center">
              {navLinks.map(
                (
                  {
                    subNav,
                    to,
                    text,
                    navId,
                    subNavLinks,
                    attrs = {},
                    external = false,
                  },
                  i
                ) => {
                  if (subNav) {
                    return (
                      <>
                        {/* Show only on larger screens */}
                        <ProperLink
                          key={`${i}-desktop`}
                          className={`${styles.navLink} d-none d-lg-inline-block`}
                          activeClassName={styles.activeLink}
                          to={to}
                          attrs={attrs}
                          external={external}
                        >
                          {text}
                        </ProperLink>
                        {/* Show only on mobile */}
                        <NavDropdown
                          key={`${i}-mobile`}
                          title={text}
                          className={`d-lg-none ${styles.navLinkDropDown}`}
                          id="collasible-nav-dropdown"
                        >
                          {subNavLinks.map((subNavLink, i) => {
                            const { attrs = {}, external = false } = subNavLink
                            const linkHref = !!subNavLink.excludeMainLink
                              ? subNavLink.to
                              : `${to}${subNavLink.to}`
                            return (
                              <ProperLink
                                key={i}
                                className={`dropdown-item ${styles.navLink}`}
                                activeClassName={styles.activeLink}
                                to={linkHref}
                                attrs={attrs}
                                external={external}
                              >
                                {subNavLink.text}
                              </ProperLink>
                            )
                          })}
                        </NavDropdown>
                      </>
                    )
                  }

                  return (
                    <ProperLink
                      className={styles.navLink}
                      activeClassName={styles.activeLink}
                      to={to}
                      attrs={attrs}
                      external={external}
                    >
                      {text}
                    </ProperLink>
                  )
                }
              )}
            </Nav>
            <a
              className={`rounded btn btn-primary ${styles.phoneLink}`}
              href={`tel:${phoneNumber}`}
            >
              <FaPhoneAlt
                className={`${styles.phoneIcon} d-lg-none d-xl-inline-block`}
              />
              <span>{phoneNumberFormat}</span>
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Progressbar under mainNav, used for blog page */}
      <ProgressBar visible={hasProgressBar} />

      {/* Has Sub Navigation in Large Screens */}
      <SubHeader hasSubNav={hasSubNav} navLink={subNavLink} visible={visible} />
    </>
  )
}

Header.propTypes = {
  hasSubNav: PropTypes.bool.isRequired,
  navId: PropTypes.string.isRequired,
  hasProgressBar: PropTypes.bool.isRequired,
}

export default Header

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Map, Marker, GoogleApiWrapper } from "google-maps-react"

import config from "../../../data/siteConfig"

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "400px",
}

export const MapContainer = ({ address, google }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          googleMaps {
            zoom
          }
          maps {
            perth {
              title
              address
              center {
                lat
                lng
              }
            }
            sydney {
              title
              address
              center {
                lat
                lng
              }
            }
          }
        }
      }
    }
  `)

  const { maps, googleMaps } = data.site.siteMetadata

  return (
    <div className="container section-gap">
      <h2 className="d-block font-bold text-center mb-5">
        We Operate In {maps[address].title}
      </h2>
      <h3 className="font-semibold d-block text-center mb-5">
        {maps[address].address}
      </h3>
      <Map
        containerStyle={containerStyle}
        google={google}
        zoom={googleMaps.zoom}
        initialCenter={maps[address].center}
      >
        <Marker position={maps[address].center} name={"Current location"} />
      </Map>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: config.googleMaps.apiKey,
})(MapContainer)

const config = {
  siteTitle:
    "Wills & Probate Solicitors | Deceased Estate in Sydney - ESTATES PLUS", // Site title.
  siteTitleShort: "Estates Plus", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Estates Plus", // Alternative site title for SEO.
  siteLogo: "/icons/favicon.png", // Logo used for SEO and manifest.
  siteUrl: "https://www.estatesplus.com.au/", // Domain of your website without pathPrefix.
  siteEmail: `help@estatesplus.com.au`,
  siteImage: "/img/homepage.jpeg",
  siteAuthor: "Estates Plus",
  siteDeveloper: "Kim Lian Lopez",
  pathPrefix: "", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    "ESTATES PLUS – PROBATE and deceased estate lawyers in SYDNEY. Access assetsand legal documents for deceased estate online. Prepared by executor of will &amp;estate in Australia. Save on legal fees.", // Website description used for RSS feeds/meta description tag.
  defaultImageAlt: "by Estates Plus",
  phoneNumber: "1300967552",
  phoneNumberFormat: "1300 967 552",
  googleMaps: {
    apiKey: `AIzaSyBTmF7yk3nE-FdDZvHAjQ46CX9eJqbHpbg`,
    zoom: 15,
  },
  maps: {
    perth: {
      title: "Perth",
      address: "Level 4, 200 Adelaide Terrace, PERTH WA 6004",
      center: { lat: -31.95843, lng: 115.869398 },
    },
    sydney: {
      title: "Sydney",
      address: "Level 11, 66 Clarence Street SYDNEY NSW 2000",
      center: { lat: -33.866402, lng: 151.20508 },
    },
  },
  // siteRss: '/rss.xml', // Path to the RSS file.
  // siteFBAppID: '1825356251115265', // FB Application ID for using app insights
  googleAnalyticsID: "UA-159259621-1", // GA tracking ID.
  googleTagManagerID: "GTM-PKGBLHV", // GA tracking ID.
  facebookPixelID: "370851683438416", // GA tracking ID.
  // disqusShortname: 'https-vagr9k-github-io-gatsby-advanced-starter', // Disqus shortname.
  // postDefaultCategoryID: 'Tech', // Default category for posts.
  // dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  // dateFormat: 'DD/MM/YYYY', // Date format for display.
  // postsPerPage: 4, // Amount of posts displayed per listing page.
  // userName: 'Advanced User', // Username to display in the author segment.
  // userEmail: 'AdvancedUser@example.com', // Email used for RSS feed's author segment
  // userTwitter: '', // Optionally renders "Follow Me" in the UserInfo segment.
  // userLocation: 'North Pole, Earth', // User location to display in the author segment.
  // userAvatar: 'https://api.adorable.io/avatars/150/test.png', // User avatar to display in the author segment.
  // userDecription:
  // "Yeah, I like animals better than people sometimes... Especially dogs. Dogs are the best. Every time you come home, they act like they haven't seen you in a year. And the good thing about dogs... is they got different dogs for different people.", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  // userLinks: [
  //   {
  //     label: 'GitHub',
  //     url: 'https://github.com/Vagr9K/gatsby-advanced-starter',
  //     iconClassName: 'fa fa-github',
  //   },
  //   {
  //     label: 'Twitter',
  //     url: 'https://twitter.com/Vagr9K',
  //     iconClassName: 'fa fa-twitter',
  //   },
  //   {
  //     label: 'Email',
  //     url: 'mailto:vagr9k@gmail.com',
  //     iconClassName: 'fa fa-envelope',
  //   },
  // ],
  // copyright: 'Copyright © 2019. Advanced User', // Copyright string for the footer of the website and RSS feed.
  // themeColor: '#c62828', // Used for setting manifest and progress theme colors.
  // backgroundColor: '#e0e0e0', // Used for setting manifest background color.
}

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = ""
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1)

// // Make sure siteRss has a starting forward slash
// if (config.siteRss && config.siteRss[0] !== '/')
//   config.siteRss = `/${config.siteRss}`;

module.exports = config

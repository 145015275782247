import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import PropTypes from "prop-types"

const PreviewCompatibleImage = ({ src, alt, style, className, ...props }) => {
  const altTemplate = !!alt ? alt : ""

  if (!!src && typeof src === "string") {
    return (
      <img
        src={src}
        alt={altTemplate}
        className={className}
        style={{ width: "100%", ...style }}
      />
    )
  }

  if (!!src.childImageSharp) {
    const { fluid, fixed } = src.childImageSharp

    if (!!fluid) {
      return (
        <Image
          fluid={fluid}
          alt={altTemplate}
          className={className}
          style={{ width: "100%", ...style }}
          {...props}
        />
      )
    }

    if (!!fixed) {
      return (
        <Image
          fixed={fixed}
          alt={altTemplate}
          className={className}
          style={style}
          {...props}
        />
      )
    }
  }

  return ""
}

PreviewCompatibleImage.defaultProps = {
  alt: "",
  style: {},
  className: "",
}

PreviewCompatibleImage.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  alt: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
}

export default PreviewCompatibleImage

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { concat } from "lodash"

function SEO({ title, description, image, type, meta, lang, path }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            description
            siteImage
          }
        }
      }
    `
  )

  const { siteMetadata } = site

  const siteTitle = title || siteMetadata.title
  const siteDescription = description || siteMetadata.description
  const baseUrl = siteMetadata.siteUrl
  const siteImage = !!image ? `${baseUrl}${image}` : siteMetadata.siteImage
  const siteUrl = `${baseUrl}${path}`

  // Default Meta Tags
  const defaultMeta = [
    {
      name: `description`,
      content: siteDescription,
    },
    {
      name: `image`,
      content: siteImage,
    },
  ]

  // Google / Search Engine Tags
  const googleMeta = [
    {
      itemprop: `name`,
      content: siteTitle,
    },
    {
      itemprop: `description`,
      content: siteDescription,
    },
    {
      itemprop: `image`,
      content: siteImage,
    },
  ]

  // Facebook Meta Tags
  const facebookMeta = [
    {
      property: `og:title`,
      content: siteTitle,
    },
    {
      property: `og:description`,
      content: siteDescription,
    },
    {
      property: `og:type`,
      content: type,
    },
    {
      property: `og:url`,
      content: siteUrl,
    },
    {
      property: `og:image`,
      content: siteImage,
    },
  ]

  // Twitter Meta Tags
  const twitterMeta = [
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:title`,
      content: siteTitle,
    },
    {
      name: `twitter:description`,
      content: siteDescription,
    },
    {
      name: `twitter:image`,
      content: siteImage,
    },
  ]

  const completeMetadata = concat(
    meta,
    defaultMeta,
    googleMeta,
    facebookMeta,
    twitterMeta
  )

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={siteTitle}
      meta={completeMetadata}
    >
      <link
        rel="icon"
        type="image/png"
        href="/icons/favicon.png"
        sizes="16x16"
      />
      <link
        rel="stylesheet"
        href="https://assets.reviews.io/css/widgets/carousel-widget.css?_t=2021071501"
      />
      <link
        rel="stylesheet"
        href="https://assets.reviews.io/iconfont/reviewsio-icons/style.css?_t=2021071501"
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: ``,
  image: ``,
  type: `website`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
}

export default SEO

import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types"

import "./progressbar-style.scss"

const ProgressBar = ({ visible }) => {
  const [scrolled, setScrolled] = useState(0);

  useEffect(() => {
    const scrollProgress = () => {
      const scrollPx = document.documentElement.scrollTop
      const winHeightPx =
        (document.documentElement.scrollHeight - document.documentElement.clientHeight) - 600
      const scrolled = `${scrollPx / winHeightPx * 100}%`

      setScrolled(scrolled)
    }

    window.addEventListener('scroll', scrollProgress);

    return () => window.removeEventListener('scroll', scrollProgress);

  }, [scrolled])

  const progressContainerStyle = {
    background: "#ccc",
    height: "5px",
    position: "fixed",
    left: 0,
    width: "100vw",
    zIndex: 1030,
    display: visible ? "block" : "none"
  };

  const progressBarStyle = {
    height: "5px",
    background: "#00b5f6",
    width: scrolled
  };

  return (
    <div className="progress-container" style={progressContainerStyle}>
      <div className="progress-bar" style={progressBarStyle} />
    </div>
  )
}

ProgressBar.propTypes = {
  visible: PropTypes.bool.isRequired
}

export default ProgressBar